module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MainOrder","short_name":"maino","start_url":"/","background_color":"#760F19","theme_color":"#760F19","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"573aa029b1d7d75dde9045f32df6ac09"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
